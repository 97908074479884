import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import rehypeReact from 'rehype-react';

import Layout from '../components/layout';
import MainBox from '../components/MainBox';
import Box from '@material-ui/core/Box';
import FormatQuoteIcon from '@material-ui/icons/FormatQuoteRounded';
import Seo from '../components/seo';
import Toc2 from '../components/Toc';
import colors from '../colors';

const CustomLink = withStyles({
  link: {
    // fontSize: "1rem",
    // color: colors.primary,
  },
  text: {
    display: 'inline',
    // color: colors.primary,
  },
})(({ children, classes, href = '', ...rest }) => {
  // Detect local links in a super simple way, if so use Link otherwise use an A element with a target="_blank"
  const isLocalLink = href.substring(0, 1) === '/';

  if (isLocalLink) {
    return (
      <Link className={classes.link} to={href} {...rest}>
        {/* <Typography variant="body1" component="span" className={classes.text}> */}
        {children}
        {/* </Typography> */}
      </Link>
    );
  } else {
    return (
      <a
        className={classes.link}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        {...rest}
      >
        {/* <Typography variant="body1" component="span" className={classes.text}> */}
        {children}
        {/* </Typography> */}
      </a>
    );
  }
});

const H1 = ({ children }) => {
  return (
    <Box mt={3}>
      <Typography variant="h1">{children}</Typography>
    </Box>
  );
};

const H2 = ({ children, id }) => {
  return (
    <Box id={id} style={{ scrollMarginTop: 120 }}>
      <Typography variant="h2">{children}</Typography>
    </Box>
  );
};

const H3 = (props) => {
  const { children, id } = props;
  return (
    <Box id={id} style={{ scrollMarginTop: 120 }}>
      <Typography variant="h3">{children}</Typography>
    </Box>
  );
};

const SpecialQuote = ({ children, author }) => {
  //   console.log("SpecialQuote children", children)
  const quote = children[0] || '';
  if (!quote) return null;

  return (
    <Box
      style={{ backgroundColor: '#eee', borderRadius: 4 }}
      p={4}
      pt={6}
      pb={6}
      mb={1}
    >
      {/* <p> */}
      <Typography
        variant="h3"
        style={{
          color: '#444',
          fontWeight: 500,
          lineHeight: 1.3,
        }}
      >
        <FormatQuoteIcon
          style={{
            color: '#aaa',
            fontSize: 28,
            marginRight: 4,
          }}
        />
        {quote}
        {/* <FormatQuoteIcon
          style={{ color: "#aaa", fontSize: 28, marginLeft: 4 }}
        /> */}
      </Typography>
      {/* </p> */}
    </Box>
  );
};

const p = ({ children }) => {
  return (
    <Box mt={1} mb={5}>
      {children}
    </Box>
  );
};

const blockquote = ({ children }) => {
  return (
    <Box
      mt={3}
      mb={3}
      style={{
        backgroundColor: '#eee',
        borderLeft: `3px solid ${colors.primary}`,
      }}
      p={4}
      pt={2}
      pb={2}
    >
      {children}
    </Box>
  );
};

const copyToClipboard = (text) => {
  // Create a text area element
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Set the position to be off-screen
  textArea.style.position = 'fixed';
  textArea.style.left = '-9999px';

  // Append the text area to the document
  document.body.appendChild(textArea);

  // Select and copy the text inside the text area
  textArea.select();
  document.execCommand('copy');

  // Remove the temporary text area from the document
  document.body.removeChild(textArea);
};

const Pre = ({ children, className }) => {
  const [buttonText, setButtonText] = useState('copy');

  const onCopyClick = (event) => {
    const preElement = event.target.closest('pre');
    const codeElement = preElement.querySelector('code');
    const textToCopy = codeElement.innerText || codeElement.textContent;
    copyToClipboard(textToCopy);
    setButtonText('✓ copied');

    setTimeout(() => {
      setButtonText('copy');
    }, 2000);
  };

  return (
    <pre className={className} style={{ position: 'relative' }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'absolute',
          right: 10,
          top: 10,
          fontSize: 10,
        }}
      >
        <Button
          size="small"
          variant="outlined"
          onClick={onCopyClick}
          color="secondary"
        >
          {buttonText}
        </Button>
      </Box>

      {children}
    </pre>
  );
};

const code = ({ children, className }) => {
  return (
    <code className={className} style={{ position: 'relative' }}>
      {children}
    </code>
  );
};

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h1: H1,
    h2: H2,
    h3: H3,
    p,
    pre: Pre,
    // code,
    blockquote,
    'special-quote': SpecialQuote,
    // li: Li,
    a: CustomLink,
  },
}).Compiler;

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, htmlAst } = markdownRemark;
  const { title, toc, description, slug, image, author } = frontmatter;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        author={author}
      />
      {/* <Container maxWidth="md"> */}
      <div style={{ display: 'flex' }}>
        <MainBox maxWidth={860}>{renderAst(htmlAst)}</MainBox>
        {toc && toc.length > 0 ? <Toc2 items={toc} slug={slug} /> : null}
      </div>
      {/* </Container> */}
    </Layout>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      htmlAst
      frontmatter {
        slug
        title
        published
        description
        image
        toc {
          text
          hash
        }
      }
    }
  }
`;
