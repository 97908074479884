import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from 'gatsby';

import useThrottledOnScroll from '../useThrottledOnScroll';

const useStyles = makeStyles((theme) => ({
  root: {
    top: 70,
    position: 'sticky',
    height: 'calc(100vh - 70px)',
    overflowY: 'auto',
    paddingTop: 64,
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'block',
    },
  },
  contents: {
    fontWeight: 500,
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  ul: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  item: {
    fontSize: '.8125rem',
    padding: theme.spacing(0.5, 0, 0.5, '5px'),
    borderLeft: `4px solid transparent`,
    boxSizing: 'border-box',
    '&:hover': {
      borderLeftColor: '#aaa',
    },
    '&$active,&:active': {
      borderLeftColor: '#039BE5',
    },
  },
  secondaryItem: {
    paddingLeft: theme.spacing(2.5),
  },
  active: {},
}));

function getItemsClient(headings) {
  const itemsWithNode = [];

  const getElementByHash = (hash) => {
    return document.getElementById(hash);
  };

  headings.forEach((item) => {
    itemsWithNode.push({
      ...item,
      node: getElementByHash(item.hash),
    });

    // if (item.children.length > 0) {
    //   item.children.forEach(subitem => {
    //     itemsWithNode.push({
    //       ...subitem,
    //       node: getElementByHash(subitem.hash),
    //     })
    //   })
    // }
  });
  return itemsWithNode;
}

export default function AppTableOfContents(props) {
  const { items } = props;
  const classes = useStyles();

  const itemsWithNodeRef = React.useRef([]);
  React.useEffect(() => {
    itemsWithNodeRef.current = getItemsClient(items);
  }, [items]);

  const activePage = { pathname: props.slug };
  const [activeState, setActiveState] = React.useState(null);
  const clickedRef = React.useRef(false);
  const unsetClickedRef = React.useRef(null);
  const findActiveIndex = React.useCallback(() => {
    if (clickedRef.current) {
      return;
    }

    let active;
    for (let i = itemsWithNodeRef.current.length - 1; i >= 0; i -= 1) {
      // No hash if we're near the top of the page
      if (document.documentElement.scrollTop < 200) {
        active = { hash: null };
        break;
      }

      const item = itemsWithNodeRef.current[i];

      if (process.env.NODE_ENV !== 'production') {
        if (!item.node) {
          console.error(
            `Missing node on the item ${JSON.stringify(item, null, 2)}`,
          );
        }
      }

      if (
        item.node &&
        item.node.offsetTop <
          document.documentElement.scrollTop +
            document.documentElement.clientHeight / 2
      ) {
        active = item;
        break;
      }
    }

    if (active && activeState !== active.hash) {
      setActiveState(active.hash);
    }
  }, [activeState]);

  // Corresponds to 10 frames at 60 Hz
  useThrottledOnScroll(items.length > 0 ? findActiveIndex : null, 166);

  const handleClick = (hash) => (event) => {
    // Ignore click for new tab/new window behavior
    if (
      event.defaultPrevented ||
      event.button !== 0 || // ignore everything but left-click
      event.metaKey ||
      event.ctrlKey ||
      event.altKey ||
      event.shiftKey
    ) {
      return;
    }

    // Used to disable findActiveIndex if the page scrolls due to a click
    clickedRef.current = true;
    unsetClickedRef.current = setTimeout(() => {
      clickedRef.current = false;
    }, 1000);

    if (activeState !== hash) {
      setActiveState(hash);
    }
  };

  React.useEffect(
    () => () => {
      clearTimeout(unsetClickedRef.current);
    },
    [],
  );

  const itemLink = (item, secondary) => {
    return (
      <Link
        display="block"
        color={activeState === item.hash ? 'textPrimary' : 'textSecondary'}
        to={`${activePage.pathname}#${item.hash}`}
        underline="none"
        onClick={handleClick(item.hash)}
        className={clsx(
          classes.item,
          { [classes.secondaryItem]: secondary },
          activeState === item.hash ? classes.active : undefined,
        )}
      >
        <span dangerouslySetInnerHTML={{ __html: item.text }} />
      </Link>
    );
  };

  return (
    <nav className={classes.root}>
      {items.length > 0 ? (
        <React.Fragment>
          <Typography gutterBottom className={classes.contents}>
            Table of contents
          </Typography>
          <Typography component="ul" className={classes.ul}>
            {items.map((item) => (
              <li key={item.text}>
                {itemLink(item)}
                {/* {item.children.length > 0 ? (
                  <ul className={classes.ul}>
                    {item.children.map(subitem => (
                      <li key={subitem.text}>{itemLink(subitem, true)}</li>
                    ))}
                  </ul>
                ) : null} */}
              </li>
            ))}
          </Typography>
        </React.Fragment>
      ) : null}
    </nav>
  );
}
